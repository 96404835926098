.profile-header {
  position: relative;
  display: table;
  width: 100%;
  height: 300px;
  padding-bottom: ($jumbotron-padding * 1.6);
  margin-bottom: 0;
  text-align: center;
  background-size: cover;
  border: 0;
}
.profile-header .container,
.profile-header .container-fluid {
  display: table-cell;
  width: 1000000px; // table width hack
  text-align: center;
  vertical-align: middle;
}
.profile-header .container-inner {
  max-width: 300px;
  margin: 0 auto;
}
.profile-header .media-object {
  display: inline-block;
  width: 80px;
  margin-right: 0;
  @include box-shadow(0 1px 3px rgba(0,0,0,.05));
}
.profile-header-bio {
  font-size: 16px;
  color: #fff;
}
.profile-header-user {
  margin-top: 20px;
  color: #fff;
}
// TODO: Double check that both of these media queries work properly since they weren't exactly the same...
@include media-breakpoint-up(sm) {
  .profile-header {
    height: 400px;
  }
}
@include media-breakpoint-up(md) {
  .profile-header {
    height: 500px;
  }
  .profile-header .media-object {
    width: 110px;
  }
}


// profile header Nav
.profile-header-nav {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.profile-header-nav .nav {
  border-bottom: 0;
  vertical-align: top;
}
.profile-header-nav .nav-link {
  padding: 0 10px 18px;
  font-size: 16px;
  color: rgba(255,255,255,.5);
  @include transition(color .2s ease-in-out);
}
.profile-header-nav .nav-item {
  position: relative;
}
.profile-header-nav .nav-tabs .nav-link:focus,
.profile-header-nav .nav-tabs .nav-link:hover,
.profile-header-nav .nav-item.active .nav-link {
  color: #fff;
  background: transparent;
  border-color: transparent;
}
.profile-header-nav .active:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: '';
  margin-left: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $nav-tabs-active-link-hover-bg;
}
@include media-breakpoint-up(md) {
  .profile-header-nav .nav > li > a {
    padding-bottom: 0 15px 23px;
  }
}
